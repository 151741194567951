<template>
  <div>
    <a-spin :spinning="loading">
      <div v-if="comments.length > 0">
      <a-comment v-for="(item, index) in comments" :key="index">
        <span slot="actions" key="comment-nested-reply-to">
          <a @click="handleReply(item)">回复</a>
            <a-divider type="vertical" />
          <a
            class="delete"
            @click="handleReplyDelete(item.id)"
            >删除</a
          >
        </span>
        <a slot="author"
          >{{ item.user.user_name }} <span>{{ formatTime(item.created_at)}}</span></a
        >
        <a-avatar
          slot="avatar"
          src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
        />
        <p slot="content">
          {{ item.comment }}
        </p>
        <a-comment v-for="(item, index) in item.children" :key="index">
            <span slot="actions">
              <a
                href="javascript:;"
                class="delete"
                @click="handleReplyDelete(item.id)"
                >删除</a
              >
            </span>
            <a slot="author"
              >{{ item.admin.admin_name }} <span>{{ formatTime(item.created_at) }}</span></a
            >
            <a-avatar
              slot="avatar"
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              alt="Han Solo"
            />
            <p slot="content">
              {{ item.comment }}
            </p>
          </a-comment>
      </a-comment>
      <Pagination
        :current="current"
        :total="total"
        @handleChange="handleChange"
        v-show="total > 20"
      />
    </div>
    <p v-else style="textalign: center">暂无数据</p>
    </a-spin>

    <reply
      v-if="isShow"
      :visible.sync="isShow"
      :id="editingId"
      :recordable-type="recordableType"
      :recordable-id="editingRecordableId"
      @completed="fetchData"
    />
    
  </div>
</template>

<script>
import { CommentModel } from "@/models/comment";
const commentModel = new CommentModel();
import formatTime from "@/utils/formatTime";
import Pagination from "@/components/Pagination"

export default {
  name: 'DocumentComments',
  components: {
    Reply: () => import('@/pages/document/list/details/ReplyCommentModal'),
    Pagination
  },
  data() {
    return {
      loading: true,
      isShow: false,
      editingId: 0,
      editingRecordableId: 0,
      total: 0,
      current: 1,
      comments: [],
      reply: {},
      formatTime,
      recordableType: 'Document'
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    handleReply(item) {
      this.isShow = true
      this.editingId = item.id
      this.editingRecordableId = item.recordable_id
    },

    handleChange(page) {
      this.current = page
      this.fetchData()
    },

    handleReplyDelete(id) {
      const confirmText = ["确定删除当前评论内容吗？", "删除后将不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]));
      }
      this.$confirm({
        content: h("div", null, newDatas),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          commentModel.destroy(id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.fetchData()
            }
          });
        },
        onCancel() {},
      });
    },

    fetchData() {
      this.loading = true;
      const data = {
        recordable_id: this.id,
        recordable_type: this.recordableType,
        page: this.current
      }
      commentModel.index(data).then((res) => {
        if (res.code == 0) {
          this.comments = res.result;
          this.total = res.total
        }
        this.loading = false;
      });
    }
  }
}
</script>
